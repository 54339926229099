#about {
  padding: 50px 0;
  background-color: #fff;
  color: #000;
  font-family: 'Arial', sans-serif;
}

.about-wrapper {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.profile-pic-container {
  text-align: center;
  flex: 0 0 45%; /* Adjust the width of the profile picture container */
  margin-right: 20px; /* Space between profile picture and about content */
}

.profile-pic {
  width: 370px;
  height: 470px;
  object-fit: cover;
  border: 8px solid #fff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.profile-pic:hover {
  transform: scale(1.1);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.3);
}

.about-content {
  flex: 0 0 50%; /* Ensure the about content takes up 50% width */
  margin-top: 40px;
}

.bio-box {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.bio-box p {
  font-size: 1.2em;
  color: black;
  line-height: 1.6;
  margin-top: 15px;
}

.content-box {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
}

.content-box:hover {
  background-color: #e9e9e9;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}

.section-title {
  font-size: 1.6em;
  color: #333;
  font-weight: bold;
  margin-bottom: 15px;
  text-transform: uppercase;
}

.content-box p {
  font-size: 1.1em;
  color: black;
  line-height: 1.6;
}

/* .about-section p a {
  color: #007BFF;
  text-decoration: none;
} */

.about-section p a:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .profile-pic {
    width: 300px;
    height: 300px;
  }

  h2 {
    font-size: 2.2em;
  }

  .row {
    flex-direction: column;
    align-items: center;
  }

  .profile-pic-container, .about-content {
    flex: 0 0 100%;
    margin-right: 0;
  }

  .content-box {
    padding: 15px;
  }

  .section-title {
    font-size: 1.4em;
  }

  .content-box p {
    font-size: 1em;
  }
}
